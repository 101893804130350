document.addEventListener("DOMContentLoaded", function () {
  const prefecture_selects = document.querySelectorAll("[data-ss-prefecture]");

  /**
   * @type {AbortController} controller
   */
  const controller = {};

  function getCities(id, prefecture) {
    if (controller[`${id}`]) {
      controller[`${id}`].abort();
    }

    controller[`${id}`] = new AbortController();

    return new Promise((resolve, reject) => {
      fetch(`/wp-admin/admin-ajax.php`, {
        method: "POST",
        body: `action=get_cities&prefecture=${prefecture}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        signal: controller.signal,
      })
        .then((response) => response.json())
        .then((data) => {
          controller[`${id}`] = null;
          resolve(data.data.cities);
        });
    });
  }

  prefecture_selects.forEach((prefecture_select) => {
    prefecture_select.addEventListener("change", function () {
      const selectedPrefecture = prefecture_select.value;

      const cityId = prefecture_select.dataset.ssTargetCityId;

      const city_select = document.querySelector(`#${cityId}`);

      if (!city_select) {
        return;
      }

      getCities(cityId, selectedPrefecture).then((cities) => {
        city_select.innerHTML = "";

        const option = document.createElement("option");
        option.value = "";
        option.text = "選択する";
        city_select.appendChild(option);

        cities.forEach((city) => {
          const option = document.createElement("option");
          option.value = city;
          option.text = city;
          city_select.appendChild(option);
        });
      });
    });
  });
});
